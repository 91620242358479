@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/general-sans');

@import url(https://pro.fontawesome.com/releases/v5.10.0/css/all.css);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;800&display=swap');



.group:hover .group-hover\:inline-block {
  display: inline-block;
}
.group:hover .group-hover\:flex-grow {
  flex-grow: 1;
}


/* BlobAnimation.css */
@keyframes blobAnimation {
  0%, 100% {
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    transform: scale(1);
  }
  50% {
    border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
    transform: scale(1.1);
  }
}

 

/* feature css start */


/* @keyframes scroll {
  0% {
    transform: translateX(-100);
  }
 
  50% {
    transform: translateX(-100%);
  }
}

.animate-scrolling-logos {
  animation: scroll 20s linear infinite;
} */


/* feature css end */


/* underline css start  */

.underlinecss {
  position: relative;
}

.underlinecss:after {
  content: '';
  position: absolute;
  width: 100%;
  border-top: solid 3px red;
  left: 0;
  bottom: -10px;
  border-radius: 50%;
  height: 8px;
}

/* underline css end  */


.slider {
  height:100%;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}
.slider::before,
.slider::after{
  position:absolute;
  background-image:linear-gradient(to right,rgba(255,255,255,1) 0%,rgba(255,255,255,0) 1%);
  content:'';
  height:100%;width:25%;
  z-index:2;pointer-events:none;
}
.slider::before{
  left:0;
  top:0;
}
.slider::after{
  right:0;
  top:0;
  transform:rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW */

.slide-track {
  
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.slide-track2 {
  display: flex;
  animation: scroll2 15s linear infinite;
  justify-content: space-between;
}

.slide {
  /* width: 200px;
  height: 150px; */

  display: grid;
  place-items: center;
  transition:0.5s;
  cursor:pointer;
}
.slide:hover{
  transform:scale(0.8)
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 5));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(80px * 20);
  }

  .slide-track2 {
    width: calc(80px * 15);
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 10));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 5));
    }
  }
}







/* feature shadow css start  */
.box2{
  box-shadow: inset -5px -5px 9px rgba(255,255,255,0.45), inset 5px 5px 9px rgba(94,104,121,0.3);
  }

  .box3{
    box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
    }
/* feature shadow css end  */

/* shadow class start */
.shadowclass{
border-radius: 0 0 20px 20px ;
background: #e0e0e0;
box-shadow:  25px 25px 50px #bebebe,
             -25px -25px 50px #ffffff;}
/* shadow class end */


.gooey{
  background-image: linear-gradient(120deg, #65f2ff 0%, #d178f7 100%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  
  animation: morph 3s linear infinite; 
  transform-style: preserve-3d;
  outline: 1px solid transparent;
  will-change: border-radius;
}
.gooey:before,
.gooey:after{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0; top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(109, 163, 243, 0.824);
  will-change: border-radius, transform, opacity;
  animation-delay: 200ms;
  background-image: linear-gradient(120deg, rgb(252, 123, 241) 0%, rgba(80, 150, 255, 0.89) 100%);
}

.gooey:before{
  animation: morph 3s linear infinite;
  opacity: .21;
  animation-duration: 1.5s;
}



@keyframes morph{
  0%,100%{
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    opacity: .89;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}

@keyframes fadeIn{
  100%{
    transform: scale(1.03);
    opacity: 0;
  }}

/* gooey  tag end */




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 
  
.blur-nav{
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  }

.nav-sign-in-butten-bg-color{
  /*background-color: rgb(252,43,116);*/
   
  width: 100px;
  left: 1202px;
  height: 40px;
  top: 23px;
   
  background: #FF2C76;
  box-shadow: 0px 0px 10px #FFFFFF, 6px 6px 10px rgba(0, 0, 0, 0.2), inset -2px -2px 10px rgba(0, 0, 0, 0.25), inset 3px 3px 10px rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  font-style: 'General Sans';
}

.logo-size{
  width: 190px;
  height: 52px;
}

.search-bar-logo{
  width: 37px;
  height: 37px;
  left: 1202px;
  
  top: 23px;

  background: #f6f4f4;
  box-shadow: 0px 0px 10px #FFFFFF, 6px 6px 10px rgba(0, 0, 0, 0.2), inset -2px -2px 10px rgba(0, 0, 0, 0.25), inset 3px 3px 10px rgba(255, 255, 255, 0.3);
  border-radius: 100px;
   
}

.shopping-bar-logo{
  width: 37px;
  left: 1202px;
  height: 37px;
  top: 23px;

  background: #f6f4f4;
  box-shadow: 0px 0px 10px #FFFFFF, 6px 6px 10px rgba(0, 0, 0, 0.2), inset -2px -2px 10px rgba(0, 0, 0, 0.25), inset 3px 3px 10px rgba(255, 255, 255, 0.3);
  border-radius: 100px;
   
}

 

/* banner css start roatating word */
/* 

*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins",sans-serif;
    font-size: 45px;
    font-weight: 500;
}
 

.wrapper{
  box-sizing: content-box;
  background-color: #ffffff;
  height: 70px;
  padding: 50px 30px;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 20px 25px rgba(0,0,0,0.2);
}

.words{
  overflow: hidden;
}

span{
  display: block;
  height: 100%;
  padding-left: 10px;
  color: #df12b2;
  animation: spin_words 6s infinite;
}
@keyframes spin_words{
  10%{
      transform: translateY(-112%);
  }
  25%{
      transform: translateY(-100%);
  }
  35%{
      transform: translateY(-212%);
  }
  50%{
      transform: translateY(-200%);
  }
  60%{
      transform: translateY(-312%);
  }
  75%{
      transform: translateY(-300%);
  }
  85%{
      transform: translateY(-412%);
  }
  100%{
      transform: translateY(-400%);
  }
} */





 

 


.font-family{
  font-family: 'General Sans', sans-serif;
}




/* customer review start */
 .polka{
background-color: Black;
opacity: 1;

background-image: radial-gradient(red 0.8px, #e5e5f700 0.8px);
background-size: 18px 18px;
}

/* cusstomer review end  */


 







/* background blur css start  */
.bgblurcss{
background: hsla(0, 0%, 100%, 0.57);
/* border-radius:0 0 10px 10px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(11.6px);
-webkit-backdrop-filter: blur(11.6px);
border: 1px solid rgba(255, 255, 255, 0.25);}
/* background blur css end  */




.active{
  color:red;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-decoration: underline;
  text-underline-offset: 8px;
}

@media only screen and (max-width: 600px)  {
  .active{
    color:red;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.75rem;  
    line-height: 1rem;  
    
  }
}




/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff;  ;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
 }
 
 
 .four_zero_four_bg{
 font-size:80px;
 }
 
  .four_zero_four_bg{
       font-size:80px;
       }
       
       .link_404{      
  color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
  .contant_box_404{ margin-top:-50px;}




  /*======================
    404 page
=======================*/




   


 ::-webkit-scrollbar{
  width:0px;
 }











 /* crousel home hero section code start */



 
 .carousel {
  display: flex;
  height: 630px;
  width: 100%;
  /* max-width: 800px; */
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border-radius: 20px; */
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: 0.5s ease-in-out;
}

.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.card_image {
  width: 100%;
  object-fit: cover;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  padding: 40px 30px;
  align-items: flex-end;
}

.card_title {
  color: white;
  font-size: 30px;
}

.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 7px;
  cursor: pointer;
}

.carousel_arrow_left {
  left: 15px;
}

.carousel_arrow_right {
  right: 15px;
}

.carousel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

/* .pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
} */

/* .pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: steelblue;
} */





 /* crousel home hero section code end  */



 













/* 
 @keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.animate-marquee {
  animation: marquee var(--duration) linear infinite;
}

 */



 @keyframes marquee-left {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

@keyframes marquee-right {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.animate-marquee-left {
  animation: marquee-left var(--duration) linear infinite;
}

.animate-marquee-right {
  animation: marquee-right var(--duration) linear infinite;
}


















.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



 
 